import React from "react";
import Loadable from "react-loadable";

import { history } from "./_helpers";
//import PasswordReset from './views/Pages/PasswordReset/PasswordReset';
import { Promise } from "core-js";

import { RoleEnum } from "./_enums";
import { exact } from "prop-types";
import MasterDataReport from "./views/ManagementReports/MasterDataReport";


function Loading(props) {
  return <div>Loading...</div>;
}

const ContainerView = (View, props, permissions, extras) => {
  let hasAccess = true;
  if (permissions && permissions.length > 0) {
    hasAccess = props.roles.some((role) => permissions.includes(role));
  }
  if (!hasAccess) {
    return <h1>Access Denied</h1>;
  }

  return <View {...props} {...extras} />;
};

//const MatterView = (View, matterID) => {
//  if (matterId === 0) {
//    return <h1>Please capture a matter before you can proceed</h1>;
//  }
//  return <View />;
//};

function getMatterID() {
  var url = history.location.pathname;
  var id = parseInt(url.split("/")[2]);
  return id;
}

function getMatterSubScreenID() {
  var url = history.location.pathname;
  var id = parseInt(url.split("/")[3]);
  return id;
}

function getReferrerID() {
  return getMatterSubScreenID();
}
function getMarketerID() {
  return getMatterSubScreenID();
}

const Dashboard = Loadable({
  loader: () => import("./views/Dashboard"),
  loading: Loading,
});

const FAQ = Loadable({
  loader: () => import("./views/FAQ/FAQ"),
  loading: Loading,
});

const Matters = Loadable({
  loader: () => import("./views/Matters/Matters"),
  loading: Loading,
});

const ArchivedMatters = Loadable({
  loader: () => import("./views/Matters/Matters/ArchivedMatters"),
  loading: Loading,
});

const WorkInProgressReport = Loadable({
  loader: () => import("./views/ManagementReports/WorkInProgress"),
  loading: Loading,
  render(loaded, props) {
    var matterID = getMatterID();
    let View = loaded.default;
    let extras = { matterID: matterID };
    return ContainerView(View, props, undefined, extras);
  },
});

const RevenueReport = Loadable({
  loader: () => import("./views/ManagementReports/RevenueReport"),
  loading: Loading,
  render(loaded, props) {
    var matterID = getMatterID();
    let View = loaded.default;
    let extras = { matterID: matterID };
    return ContainerView(View, props, undefined, extras);
  },
});

const ExecutorRevenue = Loadable({
  loader: () => import("./views/ManagementReports/ExecutorRevenue"),
  loading: Loading,
  render(loaded, props) {
    var matterID = getMatterID();
    let View = loaded.default;
    let extras = { matterID: matterID };
    return ContainerView(View, props, undefined, extras);
  },
});

const ReferrerRevenue = Loadable({
  loader: () => import("./views/ManagementReports/ReferrerRevenue"),
  loading: Loading,
  render(loaded, props) {
    var matterID = getMatterID();
    let View = loaded.default;
    let extras = { matterID: matterID };
    return ContainerView(View, props, undefined, extras);
  },
});

const MarketerRevenue = Loadable({
  loader: () => import("./views/ManagementReports/MarketerRevenue"),
  loading: Loading,
  render(loaded, props) {
    var matterID = getMatterID();
    let View = loaded.default;
    let extras = { matterID: matterID };
    return ContainerView(View, props, undefined, extras);
  },
});

const FileNotesReport = Loadable({
  loader: () => import("./views/ManagementReports/FileNotesReport"),
  loading: Loading,
  render(loaded, props) {
    var matterID = getMatterID();
    let View = loaded.default;
    let extras = { matterID: matterID };
    return ContainerView(View, props, undefined, extras);
  },
});

const Matter = Loadable({
  loader: () => import("./views/Matters/Matter/Matter"),
  loading: Loading,
  render(loaded, props) {
    var matterID = getMatterID();
    let View = loaded.default;
    let extras = { matterID: matterID };

    return ContainerView(View, props, undefined, extras);
  },
});

const FlowFill = Loadable({
  loader: () => import("./views/FlowFill/FlowFill"),
  loading: Loading,
  render(loaded, props) {
    let matterID = getMatterID();
    let View = loaded.default;
    let extras = { matterID: matterID };

    return ContainerView(View, props, undefined, extras);
  },
});

const MatterDetails = Loadable({
  loader: () => import("./views/Matters/Matter/_Details"),
  loading: Loading,
});

const Diary = Loadable({
  loader: () => import("./views/Diary"),
  loading: Loading,
});

const Tasks = Loadable({
  loader: () => import("./views/Tasks/Tasks.js"),
  loading: Loading,
});

//const Reports = Loadable({
//  loader: () => import('./views/Reports'),
//  loading: Loading,
//});

const ChangePassword = Loadable({
  loader: () => import("./views/ChangePassword/ChangePassword"),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import("./views/Users/Users"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const AuditTrail = Loadable({
  loader: () => import("./views/AuditTrail/AuditTrail"),
  loading: Loading,
});

const ClientReports = Loadable({
  loader: () => import("./views/ClientReports/index"),
  loading: Loading,
});

const Details = Loadable.Map({
  loader: {
    View: () => import("./views/CompanySettings/_Details"),
  },
  loading: Loading,
  render(loaded, props) {
    debugger;
    let View = loaded.View.default;
    return (
      <View
        {...props} 
      />
    );
  },
});

const TrustFees = Loadable.Map({
  loader: {
    View: () => import("./views/TrustFees/_GlobalTrustFees.tsx"),
  },
  loading: Loading,
  render(loaded, props) {
    let View = loaded.View.default;
    return (
      <View
        {...props} 
      />
    );
  },
});

const FXAssistProviders = Loadable.Map({
  loader: {
    //FileHeaderID: () => documentService.getFile(header),
    //FileFooterID: () => documentService.getFile(footer),
    View: () => import("./views/CompanySettings/_FXAssistProviders"),
  },
  loading: Loading,
  render(loaded, props) {
    let View = loaded.View.default;
    //let FileHeaderID = loaded.FileHeaderID;
    //let FileFooterID = loaded.FileFooterID;
    return (
      <View
        {...props} /*FileHeaderID={FileHeaderID} FileFooterID={FileFooterID} */
      />
    );
  },
});

const DiaryMatter = Loadable({
  loader: () => import("./views/Matters/Matter/_DiaryMatter"),
  loading: Loading,
});

const Director = Loadable({
  loader: () => import("./views/CompanySettings/_Director"),
  loading: Loading,
});

//const Referrers = Loadable({
//  loader: () => import('./views/CompanySettings/_Referrers'),
//  hello: '123',
//  loading: Loading,
//  render(loaded, props) {
//    let Component  = loaded.namedExport;
//    return <Component  {...props} />;
//  }
//});

//https://codesandbox.io/s/lxor8v3pkz

const Referrers = Loadable.Map({
  loader: {
    ViewTest: () => import("./views/CompanySettings/_Referrers"),
    i18n: () => Promise.resolve("123"),
  },
  loading: Loading,
  render(loaded, props) {
    let ViewTest = loaded.ViewTest.default;
    let extras = { i18n: loaded.i18n };

    return ContainerView(ViewTest, props, RoleEnum.Admin.Name, extras);
  },
});

const Referrer = Loadable({
  loader: () => import("./views/CompanySettings/_Referrer"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    let extras = { referrerId: getReferrerID() };

    return ContainerView(View, props, RoleEnum.Admin.Name, extras);
  },
});

const Marketers = Loadable.Map({
  loader: {
    ViewTest: () => import("./views/CompanySettings/_Marketers"),
  },
  loading: Loading,
  render(loaded, props) {
    let ViewTest = loaded.ViewTest.default;
    return ContainerView(ViewTest, props, RoleEnum.Admin.Name);
  },
});

const Marketer = Loadable({
  loader: () => import("./views/CompanySettings/_Marketer"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    let extras = { marketerId: getMarketerID() };
    return ContainerView(View, props, RoleEnum.Admin.Name, extras);
  },
});

const BondOfSecurityProviders = Loadable({
  loader: () => import("./views/CompanySettings/_BondOfSecurityProviders"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const BondOfSecurityProvider = Loadable({
  loader: () => import("./views/CompanySettings/_BondOfSecurityProvider"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});
const FXAssistProvider = Loadable({
  loader: () => import("./views/CompanySettings/_FXAssistProvider"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});
const GlobalExecutors = Loadable({
  loader: () => import("./views/CompanySettings/_GlobalExecutors"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const GlobalFounders = Loadable({
  loader: () =>
    import("./views/CompanySettings/GlobalFounders/_GlobalFounders"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const AccountDetails = Loadable({
  loader: () => import("./views/CompanySettings/_AccountDetails"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const GlobalCreditors = Loadable({
  loader: () => import("./views/CompanySettings/_GlobalCreditors"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const GlobalCreditor = Loadable({
  loader: () => import("./views/CompanySettings/_GlobalCreditor"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const GlobalServiceProviders = Loadable({
  loader: () => import("./views/CompanySettings/_GlobalServiceProviders"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const GlobalServiceProvider = Loadable({
  loader: () => import("./views/CompanySettings/_GlobalServiceProvider"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const GlobalBeneficiaries = Loadable({
  loader: () =>
    import("./views/CompanySettings/GlobalBeneficiaries/_GlobalBeneficiaries"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const GlobalAuditors = Loadable({
  loader: () =>
    import("./views/CompanySettings/GlobalAuditors/_GlobalAuditors"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const GlobalTaxPractitioners = Loadable({
  loader: () =>
    import("./views/CompanySettings/GlobalTaxPractitioners/_GlobalTaxPractitioners"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const GlobalTrustees = Loadable({
  loader: () =>
    import("./views/CompanySettings/GlobalTrustees/_GlobalTrustees"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const PublicationPartners = Loadable({
  loader: () => import("./views/CompanySettings/_PublicationPartners"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const PublicationPartner = Loadable({
  loader: () => import("./views/CompanySettings/_PublicationPartner"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const GuardingCompanies = Loadable({
  loader: () => import("./views/CompanySettings/_GuardingCompanies"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const GuardingCompany = Loadable({
  loader: () => import("./views/CompanySettings/_GuardingCompany"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const Auctioneers = Loadable({
  loader: () => import("./views/CompanySettings/_Auctioneers"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const Auctioneer = Loadable({
  loader: () => import("./views/CompanySettings/_Auctioneer"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const Valuers = Loadable({
  loader: () => import("./views/CompanySettings/_Valuers"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const Valuer = Loadable({
  loader: () => import("./views/CompanySettings/_Valuer"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const StorageProviders = Loadable({
  loader: () => import("./views/CompanySettings/_StorageProviders"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const StorageProvider = Loadable({
  loader: () => import("./views/CompanySettings/_StorageProvider"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const LnDOptions = Loadable({
  loader: () => import("./views/CompanySettings/_LnDOptions"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const ReconOptions = Loadable({
  loader: () => import("./views/CompanySettings/_ReconOptions"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const MastersOffice = Loadable({
  loader: () => import("./views/CompanySettings/_MastersOffice"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const MasterOffice = Loadable({
  loader: () => import("./views/CompanySettings/_MasterOffice"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const MagistratesOffice = Loadable({
  loader: () => import("./views/CompanySettings/_MagistratesOffice"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const MagistrateOffice = Loadable({
  loader: () => import("./views/CompanySettings/_MagistrateOffice"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const JointAppointee = Loadable({
  loader: () => import("./views/CompanySettings/_JointAppointee"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const JointAppointees = Loadable({
  loader: () => import("./views/CompanySettings/_JointAppointees"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.Admin.Name);
  },
});

const PayoutFile = Loadable({
  loader: () => import("./views/PayoutFile/_PayoutFile"),
  loading: Loading,
});

const PrivacyAndSecurityPolicy = Loadable({
  loader: () => import("./views/Legal/PrivacyAndSecurityPolicy"),
  loading: Loading,
});

const Intermediaries = Loadable({
  loader: () => import("./views/Intermediaries/Intermediaries"),
  loading: Loading,
  render(loaded, props) {
    let View = loaded.default;
    return ContainerView(View, props, RoleEnum.SuperAdmin.Name);
  },
});

const TermsAndConditions = Loadable({
  loader: () => import("./views/Legal/TermsAndConditions"),
  loading: Loading,
});

const InformationOfficer = Loadable({
  loader: () => import("./views/Legal/InformationOfficer"),
  loading: Loading,
});

const SystemChangesSuperAdmin = Loadable({
  loader: () => import("./views/SystemChanges/SystemChangesSuperAdmin"),
  loading: Loading,
});

const Template = Loadable({
  loader: () => import("./views/CompanySettings/_Template"),
  loading: Loading,
});

const TemplateNew = Loadable({
  loader: () => import("./views/CompanySettings/_TemplateNew"),
  loading: Loading,
});

const VerifyEmail = Loadable({
  loader: () => {
    import("./views/ForgotPassword/ForgotPassword");
  },
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  //{ path: '/', exact: true, name: 'Home', component: DefaultLayout },
  //{ path: '/', exact: true, name: 'Home', component: () => <Redirect to="/dashboard" /> },
  { path: "/", exact: true, name: "Home", component: Dashboard },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/faq", name: "FAQ", component: FAQ },
  { path: "/diary", name: "Diary", component: Diary },
  { path: "/tasks", name: "Tasks", component: Tasks },
  { path: "/audittrail", name: "Audit Trail", component: AuditTrail },
  { path: "/clientReports", name: "Client Reports", component: ClientReports },
  {
    path: "/scheduler",
    name: "Scheduler",
    component: () => {
      window.location.reload();
      return "Loading";
    },
  },

  {
    path: "/reports/workinprogressreport",
    exact: true,
    name: "Work in Progress Report",
    component: WorkInProgressReport,
  },
  {
    path: "/reports/executorRevenue",
    exact: true,
    name: "Executor Revenue",
    component: ExecutorRevenue,
  },
  {
    path: "/reports/referrerRevenue",
    exact: true,
    name: "Referrer Revenue",
    component: ReferrerRevenue,
  },
  {
    path: "/reports/marketerRevenue",
    exact: true,
    name: "Marketer Revenue",
    component: MarketerRevenue,
  },
  {
    path: "/reports/fileNotesReport",
    exact: true,
    name: "File Notes Report",
    component: FileNotesReport,
  },
  {
    path: "/reports/MasterDataReport",
    exact: true,
    name: "Master Data Report",
    component: MasterDataReport,
  },
  {
    path: "/intermediaries",
    exact: true,
    name: "Intermediaries",
    component: Intermediaries,
  },
  {
    path: "/systemchangessuperadmin",
    exact: true,
    name: "System Changes",
    component: SystemChangesSuperAdmin,
  },
  { path: "/matters/:id/assets", isSubPath: true, name: "Assets" },
  { path: "/matters/:id/banking", isSubPath: true, name: "Banking" },
  {
    path: "/matters/:id/bondofsecurity",
    isSubPath: true,
    name: "Bond Of Security",
  },
  { path: "/matters/:id/creditors", isSubPath: true, name: "Creditors" },
  {
    path: "/matters/:id/creditors/:creditorId",
    isSubPath: true,
    name: "Creditor",
  },
  { path: "/matters/:id/claims", isSubPath: true, name: "Claims" },
  { path: "/matters/:id/claims/:claimId", isSubPath: true, name: "Claim" },
  { path: "/matters/:id/details", isSubPath: true, name: "Details" },
  { path: "/matters/:id/deceased", isSubPath: true, name: "Deceased" },
  { path: "/matters/:id/rebate", isSubPath: true, name: "Rapid Succession" },
  { path: "/matters/:id/liabilities", isSubPath: true, name: "Liabilities" },
  { path: "/matters/:id/income", isSubPath: true, name: "Incomes" },
  { path: "/matters/:id/expenses", isSubPath: true, name: "Expenses" },
  {
    path: "/matters/:id/cashallocationandrecon",
    isSubPath: true,
    name: "Cash Allocation and Recon",
  },
  {
    path: "/matters/:id/capitalgainstax",
    isSubPath: true,
    name: "Capital Gains Tax",
  },
  { path: "/matters/:id/report", isSubPath: true, name: "Report" },
  { path: "/matters/:id/fxAssist", isSubPath: true, name: "FX Assist" },
  { path: "/matters/:id/sharepoint", isSubPath: true, name: "Share Point" },

  {
    path: "/matters/:id/beneficiaries",
    isSubPath: true,
    name: "Beneficiaries",
  },

  { path: "/matters/:id/documents", isSubPath: true, name: "Documents" },
  { path: "/matters/:id/scenarios", isSubPath: true, name: "Scenarios" },
  { path: "/matters/:id/insolvent", isSubPath: true, name: "Insolvent" },
  {
    path: "/matters/:id/meetingsandadverts",
    isSubPath: true,
    name: "Meetings And Adverts",
  },
  { path: "/matters/:id/creditors", isSubPath: true, name: "Creditors" },
  {
    path: "/matters/:id/releaseofduties",
    isSubPath: true,
    name: "Release Of Duties",
  },
  {
    path: "/matters/:id/payoutfilematter",
    isSubPath: true,
    name: "Payout File",
  },
  { path: "/matters/:id/diarymatter", name: "Diary", component: DiaryMatter },
  { path: "/flowfill/:id", name: "FlowFill", component: FlowFill },
  { path: "/matters/:id", name: "Matter", component: Matter },
  { path: "/matters", exact: true, name: "Matters", component: Matters },
  {
    path: "/archivedmatters",
    exact: true,
    name: "Archived Matters",
    component: ArchivedMatters,
  },
  { path: "/users", exact: true, name: "Users", component: Users },
  { path: "/details", exact: true, name: "Details", component: Details },
  {
    path: "/fxAssistProviders",
    exact: true,
    name: "FX Assist Providers",
    component: FXAssistProviders,
  },
  {
    path: "/details/director/:directorId",
    name: "Legal Agent",
    component: Director,
  },
  {
    path: "/details/template/:templateId",
    name: "Template",
    component: Template,
  },
  //{ path: '/details/templateNew/:templateId', name: 'Template', component: TemplateNew},
  {
    path: "/details/templateNew/:templateId/:isStandardTemplate",
    name: "Template",
    component: TemplateNew,
  },
  { path: "/referrers", exact: true, name: "Referrers", component: Referrers },
  {
    path: "/referrers/referrer/:referrerId",
    name: "Referrer",
    component: Referrer,
  },
  { path: "/marketers", exact: true, name: "Marketers", component: Marketers },
  {
    path: "/marketers/marketer/:marketerId",
    name: "Marketer",
    component: Marketer,
  },
  {
    path: "/bondofsecurityproviders",
    exact: true,
    name: "Bond Of Security Providers",
    component: BondOfSecurityProviders,
  },
  {
    path: "/bondofsecurityproviders/bondofsecurityprovider/:bondofsecurityproviderId",
    exact: true,
    name: "Bond Of Security Provider",
    component: BondOfSecurityProvider,
  },
  {
    path: "/fxAssistProviders/fxProvider/:fxProviderId",
    exact: true,
    name: "Forex Provider",
    component: FXAssistProvider,
  },

  {
    path: "/accountdetails",
    exact: true,
    name: "Account Details",
    component: AccountDetails,
  },
  {
    path: "/changepassword",
    exact: true,
    name: "Change Password",
    component: ChangePassword,
  },
  {
    path: "/globalexecutors",
    exact: true,
    name: "Global Executors",
    component: GlobalExecutors,
  },
  {
    path: "/globalfounders",
    exact: true,
    name: "Global Founders",
    component: GlobalFounders,
  },
  // global beneficiaries
  {
    path: "/globalbeneficiaries",
    exact: true,
    name: "Global Beneficiaries",
    component: GlobalBeneficiaries,
  },
  // global auditors
  {
    path: "/globalauditors",
    exact: true,
    name: "Global Auditors",
    component: GlobalAuditors,
  },
  // global tax practitioners
  {
    path: "/globaltaxpractitioners",
    exact: true,
    name: "Global Tax Practitioners",
    component: GlobalTaxPractitioners,
  },
  // global trustees
  {
    path: "/globaltrustees",
    exact: true,
    name: "Global Trustees",
    component: GlobalTrustees,
  },
  {
    path: "/globalcreditors",
    exact: true,
    name: "Global Creditors",
    component: GlobalCreditors,
  },
  {
    path: "/globalcreditors/globalcreditor/:globalcreditorId",
    exact: true,
    name: "Global Creditor",
    component: GlobalCreditor,
  },
  {
    path: "/globalserviceproviders",
    exact: true,
    name: "Global Service Providers",
    component: GlobalServiceProviders,
  },
  {
    path: "/globalserviceproviders/globalserviceprovider/:globalserviceproviderId",
    exact: true,
    name: "Global Service Provider",
    component: GlobalServiceProvider,
  },
  {
    path: "/publicationpartners",
    exact: true,
    name: "Publication Partners",
    component: PublicationPartners,
  },
  {
    path: "/publicationpartners/publicationpartner/:publicationpartnerId",
    exact: true,
    name: "Publication Partner",
    component: PublicationPartner,
  },
  {
    path: "/guardingcompanies",
    exact: true,
    name: "Guarding Companies",
    component: GuardingCompanies,
  },
  {
    path: "/guardingcompanies/guardingcompany/:guardingcompanyId",
    exact: true,
    name: "Guarding Company",
    component: GuardingCompany,
  },
  {
    path: "/auctioneers",
    exact: true,
    name: "Auctioneers",
    component: Auctioneers,
  },
  {
    path: "/auctioneers/auctioneer/:auctioneerId",
    exact: true,
    name: "Auctioneer",
    component: Auctioneer,
  },
  { path: "/valuers", exact: true, name: "Valuers", component: Valuers },
  {
    path: "/valuers/valuer/:valuerId",
    exact: true,
    name: "Valuer",
    component: Valuer,
  },
  {
    path: "/lndoptions",
    exact: true,
    name: "Liquidation and Distribution Account Options",
    component: LnDOptions,
  },
  { path: "/trustfees", exact: true, name: "Trust Fees", component: TrustFees  },
  {
    path: "/reconOptions",
    exact: true,
    name: "Recon Options",
    component: ReconOptions,
  },
  {
    path: "/storageproviders",
    exact: true,
    name: "Storage Providers",
    component: StorageProviders,
  },
  {
    path: "/storageproviders/storageprovider/:storageproviderId",
    exact: true,
    name: "Storage Provider",
    component: StorageProvider,
  },
  {
    path: "/mastersoffice",
    exact: true,
    name: "Masters Office",
    component: MastersOffice,
  },
  {
    path: "/mastersoffice/masteroffice/:masterofficeprovideId",
    exact: true,
    name: "Master Office",
    component: MasterOffice,
  },
  {
    path: "/magistratesoffice",
    exact: true,
    name: "Magistrate's Office",
    component: MagistratesOffice,
  },
  {
    path: "/magistratesoffice/magistrateoffice/:magistrateofficeId",
    exact: true,
    name: "Magistrate Office",
    component: MagistrateOffice,
  },
  {
    path: "/jointappointees",
    exact: true,
    name: "Joint Appointees",
    component: JointAppointees,
  },
  {
    path: "/jointappointees/jointappointee/:jointappointeeId",
    exact: true,
    name: "Joint Appointee",
    component: JointAppointee,
  },
  {
    path: "/payoutfile",
    exact: true,
    name: "Payout File",
    component: PayoutFile,
  },
  {
    path: "/legal/privacyandsecuritypolicy",
    exact: true,
    name: "Privacy And Security Policy",
    component: PrivacyAndSecurityPolicy,
  },
  {
    path: "/legal/informationofficer",
    exact: true,
    name: "Information Officer",
    component: InformationOfficer,
  },
  {
    path: "/legal/termsandconditions",
    exact: true,
    name: "Terms And Conditions",
    component: TermsAndConditions,
  },
];

function getMainRoutes() {
  var routeResults = [];
  routes.forEach(function (route) {
    if (!route.isSubPath) {
      routeResults.push(route);
    }
  });
  return routeResults;
}

let mainRoutes = getMainRoutes();

export default {
  mainRoutes: mainRoutes,
  allRoutes: routes,
};

//export default routes;
