export const TableTypeEnum = {
  MarketerRevenueReport: { ID: 1, Name: "Marketer Revenue Report" },
  ReferrerRevenueReport: { ID: 2, Name: "Referrer Revenue Report" },
  ExecutorRevenueReport: { ID: 3, Name: "Executor Revenue Report" },
  WIPReport: { ID: 4, Name: "WIP Report" },
  MatterTable: { ID: 5, Name: "Matter Table" },
  FileNotesReport: { ID: 6, Name: "FileNotes Report" },
  MasterDataTable: { ID: 7, Name: "Export Master Report - Active / Pending" },
  ArchivedMatterTable: { ID: 8, Name: "Archived Matters Table" },
  MasterDataTableArchivedClosed: { ID: 9, Name: "Export Master Report - Archived / Closed" },

};
