import React, { useCallback, useMemo, useRef, useState } from "react";
import { Button, Input } from "reactstrap";
import { reportService } from "../../_services";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import { SystemTypeEnum } from "../Enums/SystemTypeEnum";
import moment from "moment";
import { CurrencyGridFormat } from "../../_formatter/CurrencyGridFormat";
import { DateFormat } from "../../_formatter/DateFormatter";
import { TableTypeEnum } from "../Enums/ManagementReportType";
import { DownloadURLFile } from "../../_components/UrlDownload";


const MasterDataReport = () => {
  React.useEffect(() => {
  });
  const onExportMasterDataArchivedClosed = (e) => {
    NotificationManager.info(" please be patient. This will take some time");
     DownloadURLFile(
       "/api/report/downloadTableCSV?reportType=" +
         TableTypeEnum.MasterDataTableArchivedClosed.ID,
       false,
     );
   };
   const onExportMasterDataActivePending = (e) => {
         NotificationManager.info(" please be patient. This will take some time");
          
       DownloadURLFile(
         "/api/report/downloadTableCSV?reportType=" +
           TableTypeEnum.MasterDataTable.ID,
         false,
       );
     };
   
    return (
        <div>
                   
            <Button
              className="m-2"
              outline
              color="primary"
              size="sm"
              onClick={onExportMasterDataActivePending}
            >
              Export Master Report - Active / Pending
            </Button>
            <Button
              className="m-2"
              outline
              color="primary"
              size="sm"
              onClick={onExportMasterDataArchivedClosed}
            >
              Export Master Report - Archived / Closed
            </Button>
            
        </div>
      );
}

export default MasterDataReport;
