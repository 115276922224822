import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { history } from "./_helpers";
import { alertActions } from "./_actions";

//import 'semantic-ui-css/semantic.min.css';
import "./semantic/dist/semantic.css";

// Styles
// CoreUI Icons Set
import "@coreui/icons/css/coreui-icons.min.css";
// Import Flag Icons Set
import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";

import "react-notifications/lib/notifications.css";

// Import Main styles for this application

import "./scss/style.css";
import "./scss/_custom.css";

import "./App.css";
//import './scss/dropdown/dropdown.js';
//import './scss/dropdown/dropdown.css';

//import 'semantic-ui-less/semantic.less';

import { PrivateRoute } from "./_components";

// Containers
import { DefaultLayout } from "./containers";
//import { Login, Page404, Page500, Register } from './views/Pages';
import { Login } from "./views/Pages/Login";
import Callback from "./views/Pages/Callback/Callback";
import { ForgotPassword } from "./views/ForgotPassword/ForgotPassword";
import { twoFactorCodeAuthentication } from "./views/Pages/Verify2FACode/Verify2FACode";
import { PasswordReset } from "./views/Pages/PasswordReset/PasswordReset";
import { SystemSelect } from "./views/SystemTypeSelect/SystemSelect";
import AccountInArrears from "./views/AccountInArrears/AccountInArrears";
import { AcceptTCs } from "./views/AcceptTCs/AcceptTCs";

import { loading } from "./_actions";

class App extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }

  componentDidMount() {
    window.onerror = (err) => {};
  }

  render() {
    const { alert } = this.props;
    return (
      //<Router history={history}>

      <Router history={history}>
        <Switch>
          <Route path="/login" name="Login Page" component={Login} />
          <Route path="/callback" name="Callback" component={Callback} />
          <Route
            path="/passwordreset"
            name="Password Reset"
            component={PasswordReset}
          />
          <Route
            path="/forgotpassword"
            name="Forgot Password"
            component={ForgotPassword}
          />
          <Route
            path="/Verify2FACode"
            name="Verify Two Factor Code"
            component={twoFactorCodeAuthentication}
          />
          <Route path="/accepttcs" name="Accept TCs" component={AcceptTCs} />
          <Route
            path="/systemselect"
            name="System Select"
            component={SystemSelect}
          />
          <Route
            path="/accountInArrears"
            name="Account is in Arrears"
            component={AccountInArrears}
          />
          {/*<Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />*/}
          <PrivateRoute path="/" name="Home" component={DefaultLayout} />
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    );
  }
}

//export default App;

function mapStateToProps(state) {
  const { alert, loader } = state;
  return {
    alert,
    loader,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    loading,
    dispatch,
  };
}

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export { connectedApp as App };
