import { countryCurrency } from "../_helpers/countryCurrency";

const locale = "en-GB";
const currencyOptions = {
  style: "decimal",
  currency: "ZAR",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
};

const currency = countryCurrency(localStorage.getItem("matterCountryDefault"));

export function CurrencyGridFormat(params) {
  var value = params.value;

  /*if (value == undefined || value === null || value === 0 || isNaN(value)) {
    return "-";
  }
  if (Number.isInteger(value)) {
    return `${value.toFixed(2)}`;
  }

  var result =
    currency +
    new Intl.NumberFormat(locale, currencyOptions).format(Math.abs(value));

  if (value < 0) {
    result = `(${result})`;
  }*/
  var result =
    currency +
    new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  return result;
}

export function CurrencyFormat(value) {
  if (value && value !== null && value !== NaN) {
    return (
      currency + new Intl.NumberFormat(locale, currencyOptions).format(value)
    );
  }
  if (value === 0) {
    return currency + "0";
  }
  return "-";
}

export function DateFormat(params) {
  if (params === undefined || params === null || params.value === undefined)
    return "-";

  var date = new Date(Date.parse(params));
  if (date.toDateString() === "Invalid Date") {
    if (params.value === null) {
      return "-";
    }
    date = new Date(Date.parse(params.value));
  }
  var fullYear = date.getFullYear();
  var fullMonth = date.getMonth() + 1;
  var fullDay = date.getUTCDate();
  if (fullYear < 10) {
    fullYear = "0" + fullYear;
  }
  if (fullMonth < 10) {
    fullMonth = "0" + fullMonth;
  }
  if (fullDay < 10) {
    fullDay = "0" + fullDay;
  }
  var newDate = fullYear + "-" + fullMonth + "-" + fullDay;

  return newDate;
}
