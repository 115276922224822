//import config from 'config';
//import config from '../webpack.config';
import { authHeader, service, serviceBuild } from "../_helpers";

export const documentService = {
  getList,
  getByMatterID,
  getByDocumentID,
  getDocumentDetailsByDocID,
  getByBondOfSecurityID,
  getByCommentID,
  getByClaimID,
  getByMeetingID,
  getByAssetID,
  add,
  filePondUpload,
  uploadDocument,
  getDocumentWithoutDataByID,
  addMatterDocument,
  addMatterComment,
  addByAuditorID,
  addByTaxPractitionerID,
  addByBeneficiaryID,
  addByDirectorID,
  addByExecutorID,
  addByPersonOfInterestID,
  addByMeetingID,
  _delete,
  getFile,
  checkAssetFile,
  sendDocumentWithOutlook,
  GetByMatterIDFXList,
  getMissingDocuments,
  uploadAllDocsToS3
};

var documents = [];
for (var i = 1; i < 5; i++) {
  documents.push({
    id: i,
    dateUploaded: "dateUploaded" + i,
    documentName: "documentName" + i,
    view: "View Document",
  });
}
function uploadAllDocsToS3(){
  return service.fetch.post(`/api/document/uploadAllDocumentsToS3Bucket` );
}
  
function getList(skip, take) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return new Promise((resolve, reject) => {
    resolve({
      items: documents.slice(skip, take + skip),
      itemsCount: documents.length,
    });
  });

}

function getMissingDocuments(matterID, documentTypes) {  
  const queryParams = new URLSearchParams({
    matterID: matterID,
  });
  documentTypes.forEach((type) => {
    queryParams.append("documentTypes", type.toString());
  });
  const url = `/api/document/getMissingDocuments?${queryParams.toString()}`;

  return service.fetch.get(url);
}

function GetByMatterIDFXList(matterID, beneficiaryId, documentTypes) {
  const queryParams = new URLSearchParams({
    matterID: matterID,
    beneficiaryId: beneficiaryId,
  });
  documentTypes.forEach((type) => {
    queryParams.append("documentTypes", type.toString());
  });
  const url = `/api/document/getByMatterIDFXList?${queryParams.toString()}`;

  return service.fetch.get(url);
}
function getByMatterID(id, skip, take, searchString, selectedDoctype) {
  
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getbymatterid`, {
      id,
      skip,
      take,
      searchString,
      selectedDoctype,
    }),
  );
}

function getByMeetingID(id) {
 
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getbymeetingid`, { id }),
  );
}

function getByBondOfSecurityID(id) {
  
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getbybondofsecurityid`, { id }),
  );
}

function sendDocumentWithOutlook(documentID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/senddocumentwithoutlook`, {
      documentID,
    }),
  );
}

function getByCommentID(id) {

  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getbycommentid`, { id }),
  );
}

function getByClaimID(id) {
    return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getbyclaimid`, { id }),
  );
}

function getByAssetID(id) {
  
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getbyassetid`, { id }),
  );
}

function getByDocumentID(id) {
  
  return service.fetch.post(
    `/api/document/downloaddocument`,
    JSON.stringify(id),
  );
}

function getDocumentDetailsByDocID(id) {
  
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getbydocumentid`, { id }),
  );
}

function addByPersonOfInterestID(id, personOfInterestID) {
  return service.fetch.post("/api/documentPersonOfInterest/add", {
    DocumentID: id,
    PersonOfInterestID: personOfInterestID,
  });
}

function addByBeneficiaryID(id, beneficiaryID) {
  return service.fetch.post("/api/documentBeneficiary/add", {
    DocumentID: id,
    BeneficiaryID: beneficiaryID,
  });
}

function addByAuditorID(id, auditorID) {
  return service.fetch.post("/api/documentAuditor/add", {
    DocumentID: id,
    AuditorID: auditorID,
  });
}

function addByDirectorID(id, directorID) {
  return service.fetch.post("/api/documentDirector/add", {
    DocumentID: id,
    DirectorID: directorID,
  });
}

function addByExecutorID(id, executorID) {
  return service.fetch.post("/api/documentExecutor/add", {
    DocumentID: id,
    ExecutorID: executorID,
  });
}

function addByMeetingID(id, meetingID) {
  return service.fetch.post("/api/documentMeeting/add", {
    DocumentID: id,
    MeetingID: meetingID,
  });
}

function addByTaxPractitionerID(id, taxPractitionerID) {
  return service.fetch.post("/api/documentTaxPractitioner/add", {
    DocumentID: id,
    taxPractitionerID: taxPractitionerID,
  });
}


function filePondUpload(file) {
  return service.fetch.post(`/api/document/FilePondUpload`, file);
}

function uploadDocument(endpoint, file) {
  debugger;
  return service.fetch.post(`/api/document/${endpoint}`, file);
}

function getDocumentWithoutDataByID(id) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/GetDocumentWithoutDataByID`, { id }),
  );
}

function getDocumentByID(id) {}

function addMatterDocument(file) {
  return service.fetch.post(`/api/document/AddMatterDocument`, file);
}

function addMatterComment(formData) {
  return service.fetch.post("/api/document/AddMatterComment", formData);
}

function add(file) {
 
  return service.fetch.post(`/api/document/UploadFile`, file);

  
}

function _delete(id) {

  return service.fetch.delete(`/api/document/_delete`, JSON.stringify(id));
}

function getFile(request) {
  return service.fetch.post(
    `/api/document/CheckAndGetFile`,
    JSON.stringify(request),
  );
}

function checkAssetFile(assetID, documentTypeID) {

  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/checkassetfile`, {
      assetID,
      documentTypeID,
    }),
  );
}
