//import config from 'config';
//import config from '../webpack.config';
import { authHeader, service, serviceBuild } from "../_helpers";

export const matterService = {
  _delete,
  add,
  addAccessUser,
  AddSpecialPowerOfAttorney,
  changeSystemTypeToDeceased,
  deleteAccessUsers,
  deleteComment,
  deleteExecutor,
  deletePowerOfAttorney,
  deleteRecipient,
  getAccessUserRights,
  getAccessUsers,
  getAssetValueOfActiveAccounts,  
  getExecutors,
  getIndependentTrustees,
  getIndividualTrustees,
  getGlobalExecutors,
  getGlobalAndLocalExecutors,
  linkExecutorToMatter,
  unlinkExecutorFromMatter,
  getIntermediaryCountry,
  getList,
  getMatterIDsAndInsolventsNames,
  getMatterIDsAndMatterRefs,
  getMatterList,
  getMatterByID,
  getMilestoneByID,
  getMatterCommentByMatterID,
  getMatterDetails,
  getMatterDetailsForTemplate,
  getMatterLabels,
  getNumberOfMatters,
  getMatterByInsolventID,
  getMilestones,
  getNumberOfActiveAccounts,
  getProjectedFees,
  getProjectedFeesAsset,
  getProjectedFeesIncome,
  getQuickGlance,
  getRecipients,
  getSpecialPowerAttorney,
  getTotalAssetValue,
  isMatterICOP,
  printFileNote,
  removeAccessUserFromMatter,
  saveExecutor,
  saveRecipient,
  update,
  updateShowInReport,
  updateMilestone,
  updateStatus,
  updateWatchListStatus,
  uploadedAcceptanceOfMastersDirectionAudit,
  uploadedAcceptanceOfTrustAsExecutorAudit,
  uploadedCertifiedExecutorIDAudit,
  uploadedDeathCertificateAudit,
  uploadedDeathNoticeAudit,
  uploadedNominationToActAsExecutorAudit,
  uploadedOriginalWillAudit,
  updateLinkedExecutor,
  checkLinkedExecutor,
  getTrustNumber,
};

function getList(skip, take, searchString, watchList, matterStatus) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  //return matters;
  //return new Promise((resolve, reject) => {
  //  resolve({
  //    items: matters.slice(skip, take + skip),
  //    itemsCount: matters.length
  //  });
  //  //resolve(matters.slice(skip, take));
  //  //resolve(matters);
  //});

  var d = new Date(2019, 10, 23, 5, 2, 1);

  var testme = { d: d };

  //var xx = service.fetch.post(`/api/matter/test`, testme);

  return service.fetch.get(`/api/matter/getlist`, {
    params: { skip, take, searchString, watchList, matterStatus },
  });

  //var matters = axios.get(`/api/matter/getlist`, { params: { skip, take, searchString }, headers: authHeader() })
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);

  //var matters = fetch(serviceBuild.buildUrl(`/api/matter/getlist`, { skip, take, searchString }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
}

function getGlobalExecutors() {
  return service.fetch.get(`/api/matter/getGlobalExecutors`);
}

function getMatterIDsAndInsolventsNames(
  skip,
  take,
  searchString,
  watchList,
  matterStatus,
) {
  return service.fetch.get(`/api/matter/getMatterIDsAndInsolventsNames`, {
    params: { skip, take, searchString, watchList, matterStatus },
  });
}

function getMatterIDsAndMatterRefs(
  skip,
  take,
  searchString,
  watchList,
  matterStatus,
) {
  return service.fetch.get(`/api/matter/getMatterIDsAndMatterRefs`, {
    params: { skip, take, searchString, watchList, matterStatus },
  });
}

function getMatterList(
  skip = null,
  take = null,
  sortModelArray = null,
  searchString = "",
  watchList = false,
) {
  //this is a remake of the getList function as that had slowed down over time
  let sortModel =
    sortModelArray.length > 0 ? sortModelArray[0] : { sort: "", colId: "" };
  let sortModelSort = sortModel.sort;
  let sortModelColId = sortModel.colId;

  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/getMatterList`, {
      skip,
      take,
      sortModelColId,
      sortModelSort,
      searchString,
      watchList,
    }),
  );
}



function getTrustNumber(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/trustnumber`, { matterID })
  )
  .then(response => {
    if (!response || response.trim() === '') {
      return '';  
    }
    return response;  
  })
  .catch(error => {
    console.error("Error fetching trust number:", error);
    return ''; 
  });
}


function getNumberOfMatters() {
  return service.fetch.get(`/api/matter/getnumberofmatters`);
}

function isMatterICOP(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/isMatterICOP`, { matterID }),
  );
}

function getMatterCommentByMatterID(id, skip, take) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/getMatterCommentByMatterID`, {
      id,
      skip,
      take,
    }),
  );
}

function getSpecialPowerAttorney(executorID, matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/getSpecialPowerAttorney`, {
      executorID,
      matterID,
    }),
  );
}

function getMilestones(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/getMilestones`, { matterID }),
  );
}

function getNumberOfActiveAccounts() {
  return service.fetch.get(`/api/matter/getactivematters`);
}

function getAssetValueOfActiveAccounts() {
  return service.fetch.get(`/api/matter/getactiveaccountassetvalue`);
}

function getProjectedFees() {
  return service.fetch.get(`/api/matter/getprojectfees`);
}

function getProjectedFeesAsset() {
  return service.fetch.get(`/api/matter/getprojectfeesasset`);
}

function getProjectedFeesIncome() {
  return service.fetch.get(`/api/matter/getprojectfeesincome`);
}

function getQuickGlance(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/getQuickGlance`, { matterID }),
  );
}

function getTotalAssetValue(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/GetTotalAssetValue`, { matterID }),
  );
}

function getMatterLabels(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/getMatterLabels`, { matterID }),
  );
}

function getMatterDetailsForTemplate(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/getMatterDetailsForTemplate`, {
      matterID,
    }),
  );
}

function getMatterDetails(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/GetMatterDetails`, { matterID }),
  );
}
function getMilestoneByID(id){
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/getMilestoneByID`, { id }),
  );
}
function getMatterByID(id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(id),
  };

  return service.fetch.post(`/api/matter/getmatterbyid`, JSON.stringify(id));

  //var matter = fetch(`/api/matter/getmatterbyid`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);

  //return matter;
}

function AddSpecialPowerOfAttorney(specialPowerOfAttorney) {
  return service.fetch.post(
    `/api/matter/AddSpecialPowerOfAttorney`,
    specialPowerOfAttorney,
  );
}

function getMatterByInsolventID(id) {
  return service.fetch.post(`/api/matter/getmatterbyinsolventid`, id);
}

function addAccessUser(user) {
  return service.fetch.post(`/api/matter/addaccessuser`, user);
}

function removeAccessUserFromMatter(user) {
  return service.fetch.post(`/api/matter/removeaccessuserfrommatter`, user);
}

function getAccessUsers(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/getAccessUsers`, { matterID }),
  );
}

function deleteComment(id) {
  return service.fetch.post(`/api/matter/deleteComment`, id);
}

function printFileNote(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/printFileNote`, { matterID }),
  );
}

function saveRecipient(model) {
  return service.fetch.post(`/api/matter/SaveRecipient`, model);
}

function getRecipients(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/GetRecipients`, { matterID }),
  );
}

function deleteRecipient(recipientID) {
  return service.fetch.post(`/api/matter/DeleteRecipient`, recipientID);
}

function saveExecutor(model) {
  return service.fetch.post(`/api/matter/SaveExecutor`, model);
}

function getExecutors(matterID, isMatter) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/GetExecutors`, { matterID }),
  );
}

function getIndependentTrustees(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/fiduciary/GetIndependentTrustees`, { matterID }),
  );
}

function getIndividualTrustees(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/fiduciary/GetIndividualTrustees`, { matterID }),
  );
}


function getGlobalAndLocalExecutors(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/GetGlobalAndLocalExecutors`, {
      matterID,
    }),
  );
}

function linkExecutorToMatter(executor) {
  
  return service.fetch.post(
    `/api/matter/LinkExecutorToMatter`,
    executor,
  );
}

function updateLinkedExecutor(executor) {
  
  return service.fetch.post(
    `/api/matter/UpdateLinkedExecutor`,
    executor,
  );
}

function checkLinkedExecutor(executor) {
  
  return service.fetch.post(
    `/api/matter/CheckIfGlobalExecutorIsLinkedToMatter`,
    executor,
  );
}



function unlinkExecutorFromMatter(executorID, matterID) {
  
  return service.fetch.post(
    `/api/matter/UnLinkExecutorToMatter`,
    JSON.stringify({
      executorID,
      matterID,
    }),
  );
}

function getAccessUserRights(matterID) {
  var returnObj = service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/getAccessUserRights`, { matterID }),
  );
  return returnObj;
}

function getIntermediaryCountry(matterID) {
  var returnObj = service.fetch.get(
    serviceBuild.buildUrl(`/api/matter/getIntermediaryCountry`, { matterID }),
  );

  return returnObj;
}

function changeSystemTypeToDeceased(matterID) {
  return service.fetch.post(`/api/matter/changeSystemTypeToDeceased`, matterID);
}

function deletePowerOfAttorney(powerOfAttorneyID) {
  return service.fetch.post(
    `/api/matter/deletePowerOfAttorney`,
    powerOfAttorneyID,
  );
}

function deleteExecutor(executorID) {
  return service.fetch.post(`/api/matter/DeleteExecutor`, executorID);
}

function deleteAccessUsers(id) {
  return service.fetch.post(`/api/matter/deleteaccessuser`, id);
}

function add(matter) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(matter)
  //};

  //var matter = fetch(`/api/matter/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);

  return service.fetch.post(`/api/matter/add`, matter);

  //return matter;
}

function update(matter) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(matter)
  //};
  //var matter = fetch(`/api/matter/update`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);

  return service.fetch.post(`/api/matter/update`, matter);

  //return matter;
}

function updateShowInReport(matter) {
  return service.fetch.post(`/api/matter/updateShowInReport`, matter);
}
function updateMilestone(milestone) {
  return service.fetch.post(`/api/matter/updateMilestone`, milestone);
}

function updateStatus(id, status, systemTypeID) {  
  return service.fetch.post(`/api/matter/updatestatus`, {
    id,
    matterStatus: status,
    systemTypeID: systemTypeID
  });

  //return matter;
}

function updateWatchListStatus(id, watchListStatus) {
  return service.fetch.post(`/api/matter/updatewatchlist`, {
    ID: id,
    watchList: watchListStatus,
  });
}

function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //return fetch(`/api/matter/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return matters;

  return service.fetch.delete(`/api/matter/_delete`, JSON.stringify(id));
}

function uploadedDeathNoticeAudit(matter) {
  return service.fetch.post(`/api/matter/uploaddeathnoticeaudit`, matter);
}

function uploadedDeathCertificateAudit(matter) {
  return service.fetch.post(`/api/matter/uploaddeathcertificateaudit`, matter);
}

function uploadedOriginalWillAudit(matter) {
  return service.fetch.post(
    `/api/matter/uploadcertificateexecutorsidaudit`,
    matter,
  );
}

function uploadedAcceptanceOfTrustAsExecutorAudit(matter) {
  return service.fetch.post(
    `/api/matter/uploadnominationtoactasexecutoraudit`,
    matter,
  );
}

function uploadedNominationToActAsExecutorAudit(matter) {
  return service.fetch.post(`/api/matter/uploadoriginalwillaudit`, matter);
}

function uploadedCertifiedExecutorIDAudit(matter) {
  return service.fetch.post(
    `/api/matter/uploadacceptanceoftrustasexecutoraudit`,
    matter,
  );
}

function uploadedAcceptanceOfMastersDirectionAudit(matter) {
  return service.fetch.post(
    `/api/matter/uploadacceptanceofmasterdirectionaudit`,
    matter,
  );
}

//function handleResponse(response) {
//  return new Promise((resolve, reject) => {
//    if (response.ok) {
//      // return json if it was returned in the response
//      var contentType = response.headers.get("content-type");
//      if (contentType && contentType.includes("application/json")) {
//        response.json().then(json => {
//          resolve(json);
//        });
//      } else {
//        resolve();
//      }
//    } else {
//      // return error message from response body
//      response.text().then(text => {
//        reject(text);
//      });
//    }
//  });
//}

//function handleError(error) {
//  return Promise.reject(error && error.message);
//}
