export function DateFormat(params) {
  var date = new Date(Date.parse(params.value));
  var fullYear = date.getFullYear();
  var fullMonth = date.getMonth() + 1;
  var fullDay = date.getDate();

  if (fullYear < 10) {
    fullYear = "0" + fullYear;
  }
  if (fullMonth < 10) {
    fullMonth = "0" + fullMonth;
  }
  if (fullDay < 10) {
    fullDay = "0" + fullDay;
  }

  var newDate = fullYear + "-" + fullMonth + "-" + fullDay;
  if (params.value == null) return "";
  else return newDate;
}

export function DateFormatDateEditor(params) {
  var date = new Date(Date.parse(params));
  var fullYear = date.getFullYear();
  var fullMonth = date.getMonth() + 1;
  var fullDay = date.getDate();

  if (fullYear < 10) {
    fullYear = "0" + fullYear;
  }
  if (fullMonth < 10) {
    fullMonth = "0" + fullMonth;
  }
  if (fullDay < 10) {
    fullDay = "0" + fullDay;
  }

  var newDate = fullDay + "/" + fullMonth + "/" + fullYear;
  if (params == null) return "";
  else return newDate;
}

export function DateFormatManual(value) {
  var date = new Date(Date.parse(value));
  var fullYear = date.getFullYear();
  var fullMonth = date.getMonth() + 1;
  var fullDay = date.getDate();

  if (fullYear < 10) {
    fullYear = "0" + fullYear;
  }
  if (fullMonth < 10) {
    fullMonth = "0" + fullMonth;
  }
  if (fullDay < 10) {
    fullDay = "0" + fullDay;
  }

  var newDate = fullYear + "-" + fullMonth + "-" + fullDay;
  if (value == null) return "";
  else return newDate;
}

export function DateTimeFormat(params) {
  if (
    params === undefined ||
    params === null ||
    params.value === undefined ||
    params.value === null
  ) {
    return "-";
  }

  var date = new Date(Date.parse(params.value ?? params));

  var fullYear = date.getFullYear();
  var fullMonth = date.getMonth() + 1;
  var fullDay = date.getDate();
  var fullHour = date.getHours();
  var fullMinute = date.getMinutes();
  var fullSecond = date.getSeconds();

  if (fullYear < 10) {
    fullYear = "0" + fullYear;
  }
  if (fullMonth < 10) {
    fullMonth = "0" + fullMonth;
  }
  if (fullDay < 10) {
    fullDay = "0" + fullDay;
  }
  if (fullHour < 10) {
    fullHour = "0" + fullHour;
  }
  if (fullMinute < 10) {
    fullMinute = "0" + fullMinute;
  }
  if (fullSecond < 10) {
    fullSecond = "0" + fullSecond;
  }

  var newDate =
    fullYear +
    "-" +
    fullMonth +
    "-" +
    fullDay +
    " " +
    fullHour +
    ":" +
    fullMinute +
    ":" +
    fullSecond;
  if (params.value || params) return newDate;
  else return "";
}

export function PercentageFormat(params) {
  if (params.value == null) return "";
  else return params.value + " %";
}
