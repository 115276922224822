export function countryCurrency(country) {
    var currency
  switch(country){
        case "South Africa":
        currency= "R "
        break;
      case "Namibia":
        currency = "N$ "
        break;
      default:
        currency = "R "
    }
  return currency;
}
