import { matterConstants } from "../_constants";
import { matterService } from "../_services";

export const matterActions = {
  setMatterID,
  getExecutors,
  getList,
  getMatterByID,
  getMilestoneByID,
  getMattersByIntermediary,
  getMatterCommentByMatterID,
  getNumberOfActiveAccounts,
  getAssetValueOfActiveAccounts,
  getProjectedFees,
  getProjectedFeesAsset,
  getProjectedFeesIncome,
  getQuickGlance,
  getMatterLabels,
  getMatterDetails,
  getMilestones,
  getRecipients,
  updateMilestone,
  addComment,
  add,
  update,
  updateStatus,
  updateWatchListStatus,
  _delete,
  uploadedDeathNoticeAudit,
  uploadedDeathCertificateAudit,
  uploadedOriginalWillAudit,
  uploadedAcceptanceOfTrustAsExecutorAudit,
  uploadedNominationToActAsExecutorAudit,
  uploadedCertifiedExecutorIDAudit,
  uploadedAcceptanceOfMastersDirectionAudit,
};

function getList(skip, take, searchString, watchList, matterStatus) {
  return (dispatch) => {
    dispatch(request());

    return matterService
      .getList(skip, take, searchString, watchList, matterStatus)
      .then(
        (matters) => dispatch(success(matters)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return { type: matterConstants.GETLIST_REQUEST };
  }
  function success(matters) {
    return { type: matterConstants.GETLIST_SUCCESS, matters };
  }
  function failure(error) {
    return { type: matterConstants.GETLIST_FAILURE, error };
  }
}

function setMatterID(matterId) {
  return (dispatch) => {
    dispatch(success(matterId));
  };
  function success(matterId) {
    return { type: matterConstants.SETMATTERID_SUCCESS, matterId };
  }
}

function getMatterCommentByMatterID(id, skip, take) {
  return (dispatch) => {
    dispatch(request());

    return matterService.getMatterCommentByMatterID(id, skip, take).then(
      (matterComments) => dispatch(success(matterComments)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: matterConstants.GETMATTERCOMMENTBYMATTERID_REQUEST };
  }
  function success(matterComments) {
    return {
      type: matterConstants.GETMATTERCOMMENTBYMATTERID_SUCCESS,
      matterComments,
    };
  }
  function failure(error) {
    return { type: matterConstants.GETMATTERCOMMENTBYMATTERID_FAILURE, error };
  }
}

function getRecipients(matterID) {
  return (dispatch) => {
    dispatch(request());

    return matterService.getRecipients(matterID).then(dispatch(success()));
    //.then((recipients) => dispatch(success(recipients)));
  };

  function request() {
    return { type: matterConstants.GETRECIPIENTS_REQUEST };
  }
  function success() {
    return { type: matterConstants.GETRECIPIENTS_SUCCESS };
  }
}

function getMilestones(matterID) {
  return (dispatch) => {
    dispatch(request());

    return matterService
      .getMilestones(matterID)
      .then((milestones) => dispatch(success(milestones)));
  };

  function request() {
    return { type: matterConstants.GETMILESTONES_REQUEST };
  }
  function success(milestones) {
    return { type: matterConstants.GETMILESTONES_SUCCESS, milestones };
  }
}

function getMattersByIntermediary() {
  return (dispatch) => {
    dispatch(request());

    return matterService.getMattersByIntermediary().then(
      (matters) => dispatch(success(matters)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: matterConstants.GETMATTERSBYINTERMEDIARY_REQUEST };
  }
  function success(matters) {
    return { type: matterConstants.GETMATTERSBYINTERMEDIARY_SUCCESS, matters };
  }
  function failure(error) {
    return { type: matterConstants.GETMATTERSBYINTERMEDIARY_FAILURE, error };
  }
}

function getQuickGlance(matterID) {
  return (dispatch) => {
    dispatch(request());

    return matterService
      .getQuickGlance(matterID)
      .then((matter) => dispatch(success(matter)));
  };
  function request() {
    return { type: matterConstants.GETQUICKGLANCE_REQUEST };
  }
  function success(matter) {
    return { type: matterConstants.GETQUICKGLANCE_SUCCESS, matter };
  }
  function failure(error) {
    return { type: matterConstants.GETQUICKGLANCE_FAILURE, error };
  }
}

function getMatterLabels(matterID) {
  return (dispatch) => {
    dispatch(request());

    return matterService.getMatterLabels(matterID).then(
      (matter) => dispatch(success(matter)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: matterConstants.GETMATTERLABELS_REQUEST };
  }
  function success(matter) {
    return { type: matterConstants.GETMATTERLABELS_SUCCESS, matter };
  }
  function failure(error) {
    return { type: matterConstants.GETMATTERLABELS_FAILURE, error };
  }
}

function getMatterDetails(matterID) {
  return (dispatch) => {
    dispatch(request());

    return matterService
      .getMatterDetails(matterID)
      .then((matter) => dispatch(success(matter)));
  };

  function request() {
    return { type: matterConstants.GETMATTERDETAILS_REQUEST };
  }
  function success(matter) {
    return { type: matterConstants.GETMATTERDETAILS_SUCCESS, matter };
  }
}

function getExecutors(matterID) {
  return (dispatch) => {
    dispatch(request());

    return matterService.getExecutors(matterID).then(
      (matter) => dispatch(success(matter)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: matterConstants.GETBYID_REQUEST };
  }
  function success(matter) {
    return { type: matterConstants.GETBYID_SUCCESS, matter };
  }
  function failure(error) {
    return { type: matterConstants.GETBYID_FAILURE, error };
  }
}
function getMatterByID(id) {
  return (dispatch) => {
    dispatch(request());

    return matterService.getMatterByID(id).then(
      (matter) => dispatch(success(matter)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: matterConstants.GETBYID_REQUEST };
  }
  function success(matter) {
    return { type: matterConstants.GETBYID_SUCCESS, matter };
  }
  function failure(error) {
    return { type: matterConstants.GETBYID_FAILURE, error };
  }
}

function getNumberOfActiveAccounts() {
  return (dispatch) => {
    dispatch(request());

    return matterService
      .getNumberOfActiveAccounts()
      .then((matters) => dispatch(success(matters)));
  };

  function request() {
    return { type: matterConstants.GETACTIVENUMBEROFACCOUNT_REQUEST };
  }
  function success(matters) {
    return { type: matterConstants.GETACTIVENUMBEROFACCOUNT_SUCCESS, matters };
  }
  function failure(error) {
    return { type: matterConstants.GETACTIVENUMBEROFACCOUNT_FAILURE, error };
  }
}

function getAssetValueOfActiveAccounts() {
  return (dispatch) => {
    dispatch(request());

    return matterService
      .getAssetValueOfActiveAccounts()
      .then((matters) => dispatch(success(matters)));
  };

  function request() {
    return { type: matterConstants.GETACTIVEACCOUNTASSETVALUE_REQUEST };
  }
  function success(matters) {
    return {
      type: matterConstants.GETACTIVEACCOUNTASSETVALUE_SUCCESS,
      matters,
    };
  }
  function failure(error) {
    return { type: matterConstants.GETACTIVEACCOUNTASSETVALUE_FAILURE, error };
  }
}

function getProjectedFees() {
  return (dispatch) => {
    dispatch(request());

    return matterService
      .getProjectedFees()
      .then((matters) => dispatch(success(matters)));
  };

  function request() {
    return { type: matterConstants.GETPROJECTEDFEES_REQUEST };
  }
  function success(matters) {
    return { type: matterConstants.GETPROJECTEDFEES_SUCCESS, matters };
  }
  function failure(error) {
    return { type: matterConstants.GETPROJECTEDFEES_FAILURE, error };
  }
}

function getProjectedFeesAsset() {
  return (dispatch) => {
    dispatch(request());

    return matterService
      .getProjectedFeesAsset()
      .then((matters) => dispatch(success(matters)));
  };

  function request() {
    return { type: matterConstants.GETPROJECTEDFEES_REQUEST };
  }
  function success(matters) {
    return { type: matterConstants.GETPROJECTEDFEES_SUCCESS, matters };
  }
  function failure(error) {
    return { type: matterConstants.GETPROJECTEDFEES_FAILURE, error };
  }
}

function getProjectedFeesIncome() {
  return (dispatch) => {
    dispatch(request());

    return matterService
      .getProjectedFeesIncome()
      .then((matters) => dispatch(success(matters)));
  };

  function request() {
    return { type: matterConstants.GETPROJECTEDFEES_REQUEST };
  }
  function success(matters) {
    return { type: matterConstants.GETPROJECTEDFEES_SUCCESS, matters };
  }
  function failure(error) {
    return { type: matterConstants.GETPROJECTEDFEES_FAILURE, error };
  }
}

function addComment(matterComment) {
  return (dispatch) => {
    dispatch(request());
    return matterService.addComment(matterComment).then(
      (matterComment) => dispatch(success(matterComment)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: matterConstants.ADDCOMMENT_REQUEST };
  }
  function success(matterComment) {
    return { type: matterConstants.ADDCOMMENT_SUCCESS, matterComment };
  }
  function failure(error) {
    return { type: matterConstants.ADDCOMMENT_FAILURE, error };
  }
}

function add(matter) {
  return (dispatch) => {
    dispatch(request());
    return matterService.add(matter).then(
      (matter) => dispatch(success(matter)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: matterConstants.ADD_REQUEST };
  }
  function success(matter) {
    return { type: matterConstants.ADD_SUCCESS, matter };
  }
  function failure(error) {
    return { type: matterConstants.ADD_FAILURE, error };
  }
}

function update(matter) {
  return (dispatch) => {
    dispatch(request());
    return matterService.update(matter).then(
      (matter) => dispatch(success(matter)),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: matterConstants.UPDATE_REQUEST };
  }
  function success(matter) {
    return { type: matterConstants.UPDATE_SUCCESS, matter };
  }
  function failure(error) {
    return { type: matterConstants.UPDATE_FAILURE, error };
  }
}

function updateMilestone(milestone) {
  return (dispatch) => {
    dispatch(request());
    return matterService
      .updateMilestone(milestone)
      .then((milestone) => dispatch(success(milestone)));
  };

  function request() {
    return { type: matterConstants.UPDATEMILESTONE_REQUEST };
  }
  function success(milestone) {
    return { type: matterConstants.UPDATEMILESTONE_SUCCESS, milestone };
  }
}
function getMilestoneByID(id) {
  return (dispatch) => {
    dispatch(request());
    return matterService
      .getMilestoneByID(id)
      .then((milestone) => dispatch(success(milestone)));
  };

  function request() {
    return { type: matterConstants.UPDATEMILESTONE_REQUEST };
  }
  function success(milestone) {
    return { type: matterConstants.UPDATEMILESTONE_SUCCESS, milestone };
  }
}

function updateStatus(id, status,systemTypeID) {
  return (dispatch) => {
    dispatch(request());
    return matterService.updateStatus(id, status, systemTypeID).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: matterConstants.UPDATESTATUS_REQUEST };
  }
  function success() {
    return { type: matterConstants.UPDATESTATUS_SUCCESS };
  }
  function failure(error) {
    return { type: matterConstants.UPDATESTATUS_FAILURE, error };
  }
}

function updateWatchListStatus(id, watchListStatus) {
  return (dispatch) => {
    dispatch(request());
    return matterService
      .updateWatchListStatus(id, watchListStatus)
      .then(dispatch(success()));
  };

  function request() {
    return { type: matterConstants.UPDATEWATCHLISTSTATUS_REQUEST };
  }
  function success() {
    return { type: matterConstants.UPDATEWATCHLISTSTATUS_SUCCESS };
  }
  function failure(error) {
    return { type: matterConstants.UPDATEWATCHLISTSTATUS, error };
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request());

    return matterService._delete(id).then(
      dispatch(success()),
      //error => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: matterConstants.DELETE_REQUEST };
  }
  function success() {
    return { type: matterConstants.DELETE_SUCCESS };
  }
  function failure(error) {
    return { type: matterConstants.DELETE_FAILURE, error };
  }
}

function uploadedDeathNoticeAudit(matter) {
  return (dispatch) => {
    dispatch(request());
    return matterService
      .uploadedDeathNoticeAudit(matter)
      .then((matter) => dispatch(success(matter)));
  };

  function request() {
    return { type: matterConstants.UPLOADEDDEATHNOTICEAUDIT_REQUEST };
  }
  function success(matter) {
    return { type: matterConstants.UPLOADEDDEATHNOTICEAUDIT_SUCCESS, matter };
  }
}

function uploadedDeathCertificateAudit(matter) {
  return (dispatch) => {
    dispatch(request());
    return matterService
      .uploadedDeathCertificateAudit(matter)
      .then((matter) => dispatch(success(matter)));
  };

  function request() {
    return { type: matterConstants.UPLOADEDDEATHCERTIFICATEAUDIT_REQUEST };
  }
  function success(matter) {
    return {
      type: matterConstants.UPLOADEDDEATHCERTIFICATEAUDIT_SUCCESS,
      matter,
    };
  }
}

function uploadedOriginalWillAudit(matter) {
  return (dispatch) => {
    dispatch(request());
    return matterService
      .uploadedOriginalWillAudit(matter)
      .then((matter) => dispatch(success(matter)));
  };

  function request() {
    return { type: matterConstants.UPLOADEDORIGINALWILLAUDIT_REQUEST };
  }
  function success(matter) {
    return { type: matterConstants.UPLOADEDORIGINALWILLAUDIT_SUCCESS, matter };
  }
}

function uploadedAcceptanceOfTrustAsExecutorAudit(matter) {
  return (dispatch) => {
    dispatch(request());
    return matterService
      .uploadedAcceptanceOfTrustAsExecutorAudit(matter)
      .then((matter) => dispatch(success(matter)));
  };

  function request() {
    return {
      type: matterConstants.UPLOADEDACCEPTANCEOFTRUSTASEXECUTORAUDIT_REQUEST,
    };
  }
  function success(matter) {
    return {
      type: matterConstants.UPLOADEDACCEPTANCEOFTRUSTASEXECUTORAUDIT_SUCCESS,
      matter,
    };
  }
}

function uploadedNominationToActAsExecutorAudit(matter) {
  return (dispatch) => {
    dispatch(request());
    return matterService
      .uploadedNominationToActAsExecutorAudit(matter)
      .then((matter) => dispatch(success(matter)));
  };

  function request() {
    return {
      type: matterConstants.UPLOADEDNOMINATIONTOACTASEXECUTORAUDIT_REQUEST,
    };
  }
  function success(matter) {
    return {
      type: matterConstants.UPLOADEDNOMINATIONTOACTASEXECUTORAUDIT_SUCCESS,
      matter,
    };
  }
}

function uploadedCertifiedExecutorIDAudit(matter) {
  return (dispatch) => {
    dispatch(request());
    return matterService
      .uploadedCertifiedExecutorIDAudit(matter)
      .then((matter) => dispatch(success(matter)));
  };

  function request() {
    return { type: matterConstants.UPLOADEDCERTIFIEDEXECUTORIDAUDIT_REQUEST };
  }
  function success(matter) {
    return {
      type: matterConstants.UPLOADEDCERTIFIEDEXECUTORIDAUDIT_SUCCESS,
      matter,
    };
  }
}

function uploadedAcceptanceOfMastersDirectionAudit(matter) {
  return (dispatch) => {
    dispatch(request());
    return matterService
      .uploadedAcceptanceOfMastersDirectionAudit(matter)
      .then((matter) => dispatch(success(matter)));
  };

  function request() {
    return {
      type: matterConstants.UPLOADEDACCEPTANCEOFMASTERSDIRECTIONAUDIT_REQUEST,
    };
  }
  function success(matter) {
    return {
      type: matterConstants.UPLOADEDACCEPTANCEOFMASTERSDIRECTIONAUDIT_SUCCESS,
      matter,
    };
  }
}
