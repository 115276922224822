import { documentConstants } from '../_constants';
import { documentService } from '../_services';

export const documentActions = {
  getList,
  getByMatterID,
  getByDocumentID,
  getDocumentDetailsByDocID,
  getByBondOfSecurityID,
  getByCommentID,
  getByClaimID,
  getByMeetingID,
  getByAssetID,
  add,
  _delete,
  getFile,
  checkAssetFile,
  GetByMatterIDFXList,
  addByBeneficiaryID,
  addByDirectorID,
  addByExecutorID,  
  getMissingDocuments
};

function getMissingDocuments(matterID, documentTypes){
  return dispatch => {
    dispatch(request());

    return documentService.getMissingDocuments(matterID, documentTypes)
      .then(
        documents => dispatch(success(documents)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: documentConstants.GETLIST_REQUEST }; }
  function success(documents) { return { type: documentConstants.GETLIST_SUCCESS, documents }; }
  function failure(error) { return { type: documentConstants.GETLIST_FAILURE, error }; }
}

function getList(skip, take) {
  return dispatch => {
    dispatch(request());

    return documentService.getList(skip, take)
      .then(
      documents => dispatch(success(documents)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: documentConstants.GETLIST_REQUEST }; }
  function success(documents) { return { type: documentConstants.GETLIST_SUCCESS, documents }; }
  function failure(error) { return { type: documentConstants.GETLIST_FAILURE, error }; }
}
function GetByMatterIDFXList( matterID, beneficiaryId, documentTypes) {
  return dispatch => {
    dispatch(request());

    return documentService.GetByMatterIDFXList(matterID, beneficiaryId, documentTypes)
      .then(
        documents => dispatch(success(documents)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: documentConstants.GETLIST_REQUEST }; }
  function success(documents) { return { type: documentConstants.GETLIST_SUCCESS, documents }; }
  function failure(error) { return { type: documentConstants.GETLIST_FAILURE, error }; }
}

function getByMatterID(id, skip, take, searchString, selectedDoctype) {
  return dispatch => {
    dispatch(request());
    return documentService.getByMatterID(id, skip, take, searchString, selectedDoctype)
      .then(
      documents => dispatch(success(documents)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: documentConstants.GETBYMATTERID_REQUEST } }
  function success(documents) { return { type: documentConstants.GETBYMATTERID_SUCCESS, documents } }
  function failure(error) { return { type: documentConstants.GETBYMATTERID_FAILURE, error } }
}

function getByMeetingID(id) {
  return dispatch => {
    dispatch(request());
    return documentService.getByMeetingID(id)
      .then(
        documents => dispatch(success(documents)),
      );
  };

  function request() { return { type: documentConstants.GETBYMEETINGID_REQUEST } }
  function success(documents) { return { type: documentConstants.GETBYMEETINGID_SUCCESS, documents } }
  function failure(error) { return { type: documentConstants.GETBYMEETINGID_FAILURE, error } }
}


function getByCommentID(id) {
  return dispatch => {
    dispatch(request());
    return documentService.getByCommentID(id)
      .then(
        documents => dispatch(success(documents)),
      );
  };

  function request() { return { type: documentConstants.GETBYCOMMENTID_REQUEST } }
  function success(documents) { return { type: documentConstants.GETBYCOMMENTID_SUCCESS, documents } }
  function failure(error) { return { type: documentConstants.GETBYCOMMENTID_FAILURE, error } }
}

function getByBondOfSecurityID(id) {
  return dispatch => {
    dispatch(request());
    return documentService.getByBondOfSecurityID(id)
      .then(
        documents => dispatch(success(documents)),
      );
  };

  function request() { return { type: documentConstants.GETBYBONDOFSECURITYID_REQUEST } }
  function success(documents) { return { type: documentConstants.GETBYBONDOFSECURITYID_SUCCESS, documents } }
  function failure(error) { return { type: documentConstants.GETBYBONDOFSECURITYID_FAILURE, error } }
}

function getByClaimID(id, docType) {
  return dispatch => {
    dispatch(request());
    return documentService.getByClaimID(id, docType)
      .then(
        documents => dispatch(success(documents)),
      );
  };

  function request() { return { type: documentConstants.GETBYCLAIMID_REQUEST } }
  function success(documents) { return { type: documentConstants.GETBYCLAIMID_SUCCESS, documents } }
  function failure(error) { return { type: documentConstants.GETBYCLAIMID_FAILURE, error } }
}

function getByAssetID(id) {
  return dispatch => {
    dispatch(request());
    return documentService.getByAssetID(id)
      .then(
        documents => dispatch(success(documents)),
      );
  };

  function request() { return { type: documentConstants.GETBYASSETID_REQUEST } }
  function success(documents) { return { type: documentConstants.GETBYASSETID_SUCCESS, documents } }
  function failure(error) { return { type: documentConstants.GETBYASSETID_FAILURE, error } }
}

function getByDocumentID(id) {
  return dispatch => {
    dispatch(request());

    return documentService.getByDocumentID(id)
      .then(
      documents => dispatch(success(documents)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: documentConstants.GETBYDOCUMENTID_REQUEST } }
  function success(documents) { return { type: documentConstants.GETBYDOCUMENTID_SUCCESS, documents } }
  function failure(error) { return { type: documentConstants.GETBYDOCUMENTID_FAILURE, error } }
}

function getDocumentDetailsByDocID(id) {
  return dispatch => {
    dispatch(request());

    return documentService.getDocumentDetailsByDocID(id)
      .then(
      documents => dispatch(success(documents)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: documentConstants.GETBYDOCUMENTID_REQUEST } }
  function success(documents) { return { type: documentConstants.GETBYDOCUMENTID_SUCCESS, documents } }
  function failure(error) { return { type: documentConstants.GETBYDOCUMENTID_FAILURE, error } }
}

function add(file) {
  return dispatch => {
    dispatch(request());

    return documentService.add(file);
      //.then(
      //  dispatch(success()),
      //  error => dispatch(failure(error.toString()))
      //);
  };
  function request() { return { type: documentConstants.ADD_REQUEST } }
  function success(response) { return { type: documentConstants.ADD_SUCCESS, response } }
  function failure(error) { return { type: documentConstants.ADD_FAILURE, error } }
}
function addByBeneficiaryID(id, beneficiaryID) {
  return dispatch => {
    dispatch(request());

    return documentService.addByBeneficiaryID(id, beneficiaryID);
    //.then(
    //  dispatch(success()),
    //  error => dispatch(failure(error.toString()))
    //);
  };
  function request() { return { type: documentConstants.ADD_REQUEST } }
  function success(response) { return { type: documentConstants.ADD_SUCCESS, response } }
  function failure(error) { return { type: documentConstants.ADD_FAILURE, error } }
}

function addByExecutorID(id, executorID){

  return dispatch => {
    dispatch(request());

    return documentService.addByExecutorID(id, executorID);

  };
  function request() {
    return { type: documentConstants.ADD_REQUEST } }
  function success(response) { return { type: documentConstants.ADD_SUCCESS, response } }
  function failure(error) { return { type: documentConstants.ADD_FAILURE, error } }
}
function addByDirectorID(id, directorID){
  return dispatch => {
    dispatch(request());

    return documentService.addByDirectorID(id, directorID);

  };
  function request() {
    return { type: documentConstants.ADD_REQUEST } }
  function success(response) { return { type: documentConstants.ADD_SUCCESS, response } }
  function failure(error) { return { type: documentConstants.ADD_FAILURE, error } }
}

//function add(document) {
//  return dispatch => {
//    dispatch(request());
//    
//    documentService.add(document)
//      .then(
//        dispatch(success()),
//        error => dispatch(failure(error.toString()))
//      );
//  };

//  function request() { return { type: documentConstants.ADD_REQUEST } }
//  function success() { return { type: documentConstants.ADD_SUCCESS } }
//  function failure(error) { return { type: documentConstants.ADD_FAILURE, error } }
//}

function _delete(id) {
  return dispatch => {
    dispatch(request());

    return documentService._delete(id)
      .then(
        dispatch(success()),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: documentConstants.DELETE_REQUEST } }
  function success() { return { type: documentConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: documentConstants.DELETE_FAILURE, error } }
}

function getFile(id) {
  return dispatch => {
    dispatch(request());
    return documentService.getFile(id)
      .then(
        document => dispatch(success(document)),
        //error => dispatch(failure(error.toString()))
      );
  };

  function request() { return { type: documentConstants.CHECKANDGETFILE_REQUEST } }
  function success(document) { return { type: documentConstants.CHECKANDGETFILE_SUCCESS, document} }
  function failure(error) { return { type: documentConstants.CHECKANDGETFILE_FAILURE, error } }
}

function checkAssetFile(assetID,documentTypeID) {
  return dispatch => {
    dispatch(request());
    return documentService.checkAssetFile(assetID, documentTypeID)
      .then(
        document => dispatch(success(document)),
      );
  };

  function request() { return { type: documentConstants.CHECKASSETFILE_REQUEST } }
  function success(document) { return { type: documentConstants.CHECKASSETFILE_SUCCESS, document } }
  function failure(error) { return { type: documentConstants.CHECKASSETFILE_FAILURE, error } }
}


